import React from "react";
import { Layout } from "../layouts";
import PageHeader from "../components/core/PageHeader";

const NotFound = () => {
  return (
    <Layout title="dasda">
      <PageHeader
        title="Page not found"
        description="Looks like you've followed a broken link or entered a URL that doesn't exist on this site."
      />
    </Layout>
  );
};

export default NotFound;
